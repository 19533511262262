import axios from 'axios';
import moment from 'moment';
import i18next from 'i18next';
import logger from './logger';
import {
  setLastAccessUser
} from './../utils/actions';

let store

export const injectStore = _store => {
  store = _store
}

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    try{
      store.dispatch(setLastAccessUser());
    }catch(e){
       console.error("Error updating last access ",e); 
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const regex = new RegExp('Network.*Error.*');
    if (error && error.message) {
      if (regex.test(error.message)) {
        logger.error('Network Error :  %o', error);
        // custom, message
        // when request timed out Serverless return 504 without any other information
        // we should fix in serverless itself
        let e = {
          response: {
            data: {
              errorMessage:
                'Network Error : Request timed out. Please try again later.',
            },
          },
        };
        return Promise.reject(e);
      }
    }
    logger.error('http errors: %o', error);
    return Promise.reject(error);
  }
);

function parseErrorCode(error) {
  //error.response.data.errorCode? error.response.data.errorCode : null;
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errorCode
  ) {
    return {
      errorCode: error.response.data.errorCode,
      errorMessage: error.response.data.errorMessage
        ? error.response.data.errorMessage
        : error.response.data.message,
    };
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data
  ) {
    return {
      errorCode: error.response.data.errorCode,
      errorMessage: error.response.data.errorMessage
        ? error.response.data.errorMessage
        : error.response.data.message,
    };
  }
  return { errorCode: '', errorMessage: '' };
}
let http = {
  get: (url, payload, headers, showLoader, user, callback) => {
    if (user !== null && user.username) {
      let totalLoginDuration = moment.duration(
        moment().diff(moment(user.loginTimeStamp))
      );
      console.log(
        'Session Active from (Sec): ',
        totalLoginDuration.asSeconds()
      );
      if (
        totalLoginDuration.asSeconds() < Number(user.expiresIn) &&
        totalLoginDuration.asSeconds() > Number(user.expiresIn) - 1800
      ) {
        //-1800 beca(use before 30 minute token expires, call for refresh.
        console.log('refresh called');
        callback && callback('Refresh Token Needed', null);
        return;
      } else if (totalLoginDuration.asSeconds() > Number(user.expiresIn)) {
        // session already expired
        console.log('session expired called');
        callback && callback('Session Expired', null);
        return;
      }
    }
    
    showLoader?.(true);
    console.log('GET Request: ' + url);
    let headersExtra = {};
    if (user && user.ip) {
      headersExtra = {
        'x-matson-ip': user.ip,
        'x-matson-city': user.city,
        'x-matson-lang': user.lang,
        'x-matson-country': user.country,
        'x-matson-session': user.sessionId,
      };
    }
    axios
      .get(url, {
        params: payload,
        headers: headers.cancelToken
          ? headers.headers
          : { ...headers, ...headersExtra },
        cancelToken: headers.cancelToken ? headers.cancelToken : null,
      })
      .then((responseData) => {
        console.log('GET Response: ' + JSON.stringify(responseData));
        showLoader?.(false);
        if (responseData) {
          callback && callback(responseData.data, null);
        }
      })
      .catch((error) => {
        showLoader?.(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
          return;
        }
        console.log('ERROR', error);
        const { errorCode, errorMessage } = parseErrorCode(error);
        let message = '';
        if (errorCode) {
          let output = {};
          const messageArgs = error.response.data.messageArgs
            ? error.response.data.messageArgs
            : null;
          if (messageArgs) {
            messageArgs.forEach((value, index) => {
              output['key' + index] = value;
            });
          }
          message = i18next.t('common:' + errorCode, output);
        } else if (errorMessage) {
          // use legacy logic
          message = error.response.data.errorMessage
            ? error.response.data.errorMessage
            : error.response.data.message
            ? error.response.data.message.replace(/\s*\[.*?\]\s*/g, '')
            : 'Something went wrong';
        }
        if (!message) {
          message = 'Something went wrong';
        }
        callback && callback(null, message);
      });
  },
  post: (url, data, headers, showLoader, user, callback) => {
    if (user !== null && user.username) {
      let totalLoginDuration = moment.duration(
        moment().diff(moment(user.loginTimeStamp))
      );
      console.log(
        'Session Active from (Sec): ',
        totalLoginDuration.asSeconds()
      );
      if (
        totalLoginDuration.asSeconds() < Number(user.expiresIn) &&
        totalLoginDuration.asSeconds() > Number(user.expiresIn) - 1800
      ) {
        //-1800 because before 30 minute token expires, call for refresh.
        callback && callback('Refresh Token Needed', null);
        return;
      } else if (totalLoginDuration.asSeconds() > Number(user.expiresIn)) {
        // session already expired
        callback && callback('Session Expired', null);
        return;
      }
    }
    showLoader(true);
    console.log('POST Request: ' + url);
    let headersExtra = {};
    if (user && user.ip) {
      headersExtra = {
        'x-matson-ip': user.ip,
        'x-matson-city': user.city,
        'x-matson-lang': user.lang,
        'x-matson-country': user.country,
        'x-matson-session': user.sessionId,
      };
    }
    axios
      .post(url, data, { headers: { ...headers.headers, ...headersExtra } })
      .then((responseData) => {
        console.log('POST Response: ' + JSON.stringify(responseData));
        showLoader(false);
        // if (responseData) {
        callback && callback(responseData.data, null);
        // }
      })
      .catch((error) => {
        showLoader(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
          return;
        }
        // console.log(JSON.stringify(error));
        console.log('Error', error);
        // error.response.data
        if (
          error.response.data.message ===
            '[401] PreAuthentication failed with error Password Expired.' ||
          error.response.data.errorMessage ===
            '[401] PreAuthentication failed with error Password Expired.'
        ) {
          // alert('Password Expired, Please reset it');
          callback && callback('Reset Password', null);
        } else if (
          error.response.data.message ===
            '[401] Password reset required for the user' ||
          error.response.data.errorMessage ===
            '[401] Password reset required for the user'
        ) {
          callback &&
            callback(
              'Direct Reset Password',
              'Password reset required for the user'
            );
        } else if (
          error.response.data.message === '[401] User is not confirmed.' ||
          error.response.data.errorMessage === '[401] User is not confirmed.'
        ) {
          callback &&
            callback(
              'User not confirmed',
              'Account not yet verified with 6 digit code.'
            );
        } else {
          const errorCode = error.response.data.errorCode
            ? error.response.data.errorCode
            : null;
          let message = '';
          if (errorCode) {
            let output = {};
            const messageArgs = error.response.data.messageArgs
              ? error.response.data.messageArgs
              : null;
            if (messageArgs) {
              messageArgs.forEach((value, index) => {
                output['key' + index] = value;
              });
            }
            message = i18next.t('common:' + errorCode, output);
          } else {
            // use legacy logic
            message = error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error.response.data.message
              ? error.response.data.message.replace(/\s*\[.*?\]\s*/g, '')
              : 'Something went wrong';
          }
          if (!message) {
            message = 'Something went wrong';
          }
          callback && callback(null, message);

          // alert(error.response.data.message);
        }
        //alert(error.message);
      });
  },
  put: (url, data, headers, showLoader, user, callback) => {
    if (user !== null) {
      let totalLoginDuration = moment.duration(
        moment().diff(moment(user.loginTimeStamp))
      );
      console.log(
        'Session Active from (Sec): ',
        totalLoginDuration.asSeconds()
      );
      if (
        totalLoginDuration.asSeconds() < Number(user.expiresIn) &&
        totalLoginDuration.asSeconds() > Number(user.expiresIn) - 1800
      ) {
        //-1800 because before 30 minute token expires, call for refresh.
        callback && callback('Refresh Token Needed', null);
        return;
      } else if (totalLoginDuration.asSeconds() > Number(user.expiresIn)) {
        // session already expired
        callback && callback('Session Expired', null);
        return;
      }
    }
    showLoader(true);
    console.log('PUT Request: ' + url);
    let headersExtra = {};
    if (user && user.ip) {
      headersExtra = {
        'x-matson-ip': user.ip,
        'x-matson-city': user.city,
        'x-matson-lang': user.lang,
        'x-matson-country': user.country,
        'x-matson-session': user.sessionId,
      };
    }
    axios
      .put(url, data, { ...headers, ...headersExtra })
      .then((responseData) => {
        console.log('PUT Response: ' + JSON.stringify(responseData));
        showLoader(false);
        // if (responseData) {
        callback && callback(responseData.data, null);
        // }
      })
      .catch((error) => {
        showLoader(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
          return;
        }
        // console.log(JSON.stringify(error));
        console.log('ERROR', error);
        // error.response.data
        if (
          error.response.data.message ===
            '[401] PreAuthentication failed with error Password Expired.' ||
          error.response.data.errorMessage ===
            '[401] PreAuthentication failed with error Password Expired.'
        ) {
          // alert('Password Expired, Please reset it');
          callback && callback('Reset Password', null);
        } else if (
          error.response.data.message ===
            '[401] Password reset required for the user' ||
          error.response.data.errorMessage ===
            '[401] Password reset required for the user'
        ) {
          callback &&
            callback(
              'Direct Reset Password',
              'Password reset required for the user'
            );
        } else if (
          error.response.data.message === '[401] User is not confirmed.' ||
          error.response.data.errorMessage === '[401] User is not confirmed.'
        ) {
          callback &&
            callback(
              'User not confirmed',
              'Account not yet verified with 6 digit code.'
            );
        } else {
          const errorCode = error.response.data.errorCode
            ? error.response.data.errorCode
            : null;
          let message = '';
          if (errorCode) {
            let output = {};
            const messageArgs = error.response.data.messageArgs
              ? error.response.data.messageArgs
              : null;
            if (messageArgs) {
              messageArgs.forEach((value, index) => {
                output['key' + index] = value;
              });
            }
            message = i18next.t('common:' + errorCode, output);
          } else {
            // use legacy logic
            message = error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error.response.data.message
              ? error.response.data.message.replace(/\s*\[.*?\]\s*/g, '')
              : 'Something went wrong';
          }
          if (!message) {
            message = 'Something went wrong';
          }
          callback && callback(null, message);
        }
        //alert(error.message);
      });
  },
  delete: (url, data, headers, showLoader, user, callback) => {
    if (user !== null) {
      let totalLoginDuration = moment.duration(
        moment().diff(moment(user.loginTimeStamp))
      );
      console.log(
        'Session Active from (Sec): ',
        totalLoginDuration.asSeconds()
      );
      if (
        totalLoginDuration.asSeconds() < Number(user.expiresIn) &&
        totalLoginDuration.asSeconds() > Number(user.expiresIn) - 1800
      ) {
        //-1800 beca(use before 30 minute token expires, call for refresh.
        console.log('refresh called');
        callback && callback('Refresh Token Needed', null);
        return;
      } else if (totalLoginDuration.asSeconds() > Number(user.expiresIn)) {
        // session already expired
        console.log('session expired called');
        callback && callback('Session Expired', null);
        return;
      }
    }
    showLoader(true);
    let headersExtra = {};
    if (user && user.ip) {
      headersExtra = {
        'x-matson-ip': user.ip,
        'x-matson-city': user.city,
        'x-matson-lang': user.lang,
        'x-matson-country': user.country,
        'x-matson-session': user.sessionId,
      };
    }
    console.log('DELETE Request: ' + url, data);
    axios({
      url,
      method: 'delete',
      data,
      headers: { ...headers.headers, ...headersExtra },
    })
      .then((responseData) => {
        console.log('DELETE Response: ' + JSON.stringify(responseData));
        showLoader(false);
        if (responseData) {
          callback && callback(responseData.data, null);
        }
      })
      .catch((error) => {
        showLoader(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
          return;
        }
        console.log('ERROR', error);
        // alert(error.message);
        const errorCode = error.response.data.errorCode
          ? error.response.data.errorCode
          : null;
        let message = '';
        if (errorCode) {
          let output = {};
          const messageArgs = error.response.data.messageArgs
            ? error.response.data.messageArgs
            : null;
          if (messageArgs) {
            messageArgs.forEach((value, index) => {
              output['key' + index] = value;
            });
          }
          message = i18next.t('common:' + errorCode, output);
        } else {
          // use legacy logic
          message = error.response.data.errorMessage
            ? error.response.data.errorMessage
            : error.response.data.message
            ? error.response.data.message.replace(/\s*\[.*?\]\s*/g, '')
            : 'Something went wrong';
        }
        if (!message) {
          message = 'Something went wrong';
        }
        callback && callback(null, message);
      });
  },
  refreshToken: (url, user, showLoader, callback) => {
    //call to token refresh and after that add user to store and continue to call API.
    showLoader(true);
    console.log('Refresh Token POST Request: ' + url);
    axios({
      method: 'post',
      url: url,
      data: { refreshToken: user.refreshToken },
      headers: { authorization: `${user.tokenType} ${user.idToken}` },
    })
      .then((responseData) => {
        console.log(
          'Refresh Token POST Response: ' + JSON.stringify(responseData)
        );
        showLoader(false);
        if (responseData) {
          callback && callback(responseData.data, null);
        }
      })
      .catch((error) => {
        showLoader(false);
        console.log('REFRESH error', error);
        window.location.href = '/signin';
      });
  },
};

export default http;
